.hide-element {
    opacity: 0;
}

.reveal-element {
    opacity: 1;
}

.depth-overview {
    margin-right: auto;
    margin-left: auto;
    color: gray;
    width: 65%;
}

@media screen and (min-width: 577px) {
    .depth-overview {
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .depth-overview {
    }
}

@media screen and (max-width: 576px) {
    .depth-overview {
        width: 100%;
    }
}

.depth-overview-title {
    text-align: center;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 992px) {
    .depth-overview-title {
        /* grid-column: 1 / span 2; */
        grid-column: 1 / span 3;
        font-size: 5.4vh;
        padding-top: 4vh;
        padding-bottom: 5vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .depth-overview-title {
        grid-column: 1 / span 2;
        font-size: 3vw;
        padding-top: 4vh;
        padding-bottom: 5vh;
    }
}

@media screen and (max-width: 576px) {
    .depth-overview-title {
        grid-column: 1 / span 3;
        font-size: 5.7vw;
        padding-top: 2vh;
        padding-bottom: 2.5vh;
    }
}

.depth-overview-content {
    /* grid-template-columns: 50% auto; */
    padding: 1px;
    border-radius: 5px 0 0 5px;
}

.depth-overview-content-initial-integration {
    width: 64%;
    margin-right: auto;
    margin-left: auto;
}

.depth-overview-content-paragraph {
    font-size: 3.3vh;
    line-height: 160%;
    text-align: left;
}

.depth-overview-content-developer-app {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: left;
    flex-direction: row;
    padding-top: 20vh;
    /* background-color: aqua; */
}

.depth-overview-content-paragraph-developer-app {
    width: 60%;
    padding-right: 13%;
}

.mini-app-as-rectangle {
    position: relative;
    /* transform: translate(-300%, 300%); */
    opacity: 1;
}

.depth-overview-content-marketplace-app-addition {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: left;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 40vh;
    /* background-color: rgb(109, 252, 252); */
}

.depth-overview-content-paragraph-marketplace-app-addition {
    width: 45%;
}

.marketplace-app {
    position: absolute;
    width: 17rem;
}

.marketplace-empty {
    width: 30rem;
}

.app-primary {
    opacity: 0;
}

.app-secondary-1 {
}

.app-secondary-2 {
}

.depth-overview-content-marketplace-app-subscription {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: left;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30vh;
}

.marketplace-app-primary-subscribe-unsubscribe {
    position: absolute;
    transition: opacity 350ms ease-out, box-shadow 300ms, visibility 350ms;
    border-radius: 7px;
}

.marketplace-app-primary-subscribe-unsubscribe:hover {
    box-shadow: -5px -5px 15px 8px rgba(0, 134, 255, .05), 5px 5px #5e4d93f0;
}

.marketplace-app-primary-unsubscribe {
    position: absolute;
}

.marketplace-app-primary-unsubscribe:hover {
    /* box-shadow: -10px -10px 15px 8px rgba(0, 134, 255, .05), 10px 10px #5e4d93f0; */
}

.depth-overview-content-workspace-mini-app-primary {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 25vh;
    gap:  20px;
}

.depth-overview-content-paragraph-workspace-mini-app-primary {
    width: 55%;
}

.mini-app-as-rectangle2 {
    position: absolute;
}

.workspace-mini-app-primary {
    width: 65%;
}