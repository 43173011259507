.homepage-header-business-values {
    margin-right: auto;
    margin-left: auto;
    color: gray;
    width: 65%;
}

.homepage-header-business-values-content {
    margin-right: auto;
    margin-left: auto;
}

@media screen and (min-width: 992px) {
    .homepage-header-business-values-content {
        width: 65%;
        max-width: 90vh;
        /* grid-template-columns: 25% auto; */
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .homepage-header-business-values-content {
        width: 90%;
    }
}

@media screen and (max-width: 576px) {
    .homepage-header-business-values-content {
        width: 100%;
        
    }
}

.homepage-header-business-values-content-personas {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    user-select: none;
}

.homepage-header-business-values-title {
    text-align: center;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 992px) {
    .homepage-header-business-values-title {
        /* grid-column: 1 / span 2; */
        grid-column: 1 / span 3;
        font-size: 5.4vh;
        padding-bottom: 5vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .homepage-header-business-values-title {
        grid-column: 1 / span 2;
        font-size: 3vw;
        padding-bottom: 5vh;
    }
}

@media screen and (max-width: 576px) {
    .homepage-header-business-values-title {
        grid-column: 1 / span 3;
        font-size: 5.7vw;
        padding-bottom: 2.5vh;
    }
}

.customer-persona-item {
    padding: 1.5% 0px;
    font-weight: bold;
    color: darkgray;
    cursor: pointer;
    transition: color 0.3s ease, border 0.3 ease;
    -webkit-transition: border 0.3s ease;
    -moz-transition: border 0.3s ease;
    -o-transition: border 0.3s ease;
}

@media screen and (min-width: 992px) {
    .customer-persona-item {
        /* grid-column-start: 1; */
        display: flex;
        grid-row-start: 2;
        /* border-right: 0.8vh solid lightgray; */
        border-bottom: 0.7vh solid lightgray;
        font-size: 3vh;
 
        justify-content: center;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .customer-persona-item {
        grid-column-start: 1;
        border-right: 0.8vh solid lightgray;
        font-size: 2.5vw;
    }
}

@media screen and (max-width: 576px) {
    .customer-persona-item {
        display: flex;
        grid-row-start: 2;
        border-bottom: 1.5vw solid lightgray;
        font-size: 5vw;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 577px) {
    .not-selected-persona-item:hover {
        color: gray;
        /* border-right-color: darkgray; */
        border-bottom-color: darkgray;
    }
}

.selected-persona-item {
    background-image: linear-gradient(45deg, #94618F 15%, #5f4d93 85%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    transition: text-shadow 0.3s ease;
    -webkit-transition: text-shadow 0.3s ease;
    -moz-transition: text-shadow 0.3s ease;
    -o-transition: text-shadow 0.3s ease;
}

@media screen and (min-width: 577px) {
    .selected-persona-item {
        /* border-right-color: #94618F; */
        border-bottom-color: #94618F;
    }
    
    .selected-persona-item:hover {
        text-shadow: 0px 0px 15px #94618f88;
    }
}

@media screen and (max-width: 576px) {
    .selected-persona-item {
        border-bottom-color: #94618F;
    }
}

.homepage-header-persona-business-values {
    width: 85%;
    max-width: 90vh;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
}

@media screen and (min-width: 577px) {
    .homepage-header-persona-business-values {
        /* grid-column-start: 2;
        grid-row: 2 / span 3; */
        /* grid-row-start: 3;
        grid-column: 1 / span 3; */
        
        margin-top: 3vh;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 2vh;
        /* column-gap: 1.5vw; */
    }
}

@media screen and (max-width: 576px) {
    .homepage-header-persona-business-values {
        grid-row-start: 3;
        grid-column: 1 / span 3;
        margin-top: 1.5vh;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 2vh;
        column-gap: 3vw;
    }
}