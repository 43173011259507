.persona-item-value-card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 0px 0px;
    background-color: #94618F;
    align-items: center;
    justify-content: center;
    /* transition: background-color 0.3s ease, box-shadow 0.3s ease; */
    transition: all 0.3s;
}

.persona-item-value-card:hover {
    /* background-color: #5e4d93f0; */
    transform: translate(0, -4px);
    box-shadow: -10px -10px 15px 8px rgba(0, 134, 255, .05), 10px 10px #5e4d93f0;
}

@media screen and (min-width: 992px) {
    .persona-item-value-card {
        min-width: 20vh;
        width: 15vw;
        max-width: 30vh;
        min-height: 20vh;
        height: 11vw;
        max-height: 26vh;
        font-size: 2vh;
        padding: 0px 5px;
    }

    /* .persona-item-value-card:hover {
        box-shadow: 0px 0px 15px #94618F;
    } */
}


@media screen and (min-width: 577px) and (max-width: 991px) {
    .persona-item-value-card {
        width: 23%;
        min-height: 15vh;
        height: 100%;
        font-size: 1.5vw;
    }
/* 
    .persona-item-value-card:hover {
        box-shadow: 0px 0px 10px #94618F;
    } */
}

@media screen and (max-width: 576px) {
    .persona-item-value-card {
        width: 45vw;
        height: 20vh;
        font-size: 4.5vw;
    }
}

.item-title-value {
    font-weight: bold;
    width: 79%;
}

@media screen and (min-width: 577px) {
    .item-title-value {
        font-size: 3.4vh;
        margin-bottom: 5%;
    }
}

@media screen and (max-width: 576px) {
    .item-title-value {
        font-size: 6.5vw;
        margin-bottom: 10%;
    }
}

.item-title-value-img {
    padding: 0 3%;
    margin-top: -10px;
}

@media screen and (min-width: 577px) {
    .item-title-value-img {
        width: 23%;
        height: auto;
    }
}

@media screen and (max-width: 576px) {
    .item-title-value-img {
        height: 10vw;
    }
}


.item-text-value {
    display: flex;
    padding: 0 5px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    justify-content: center;
}

.item-text-value-compact {
    margin-top: -15px;
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .item-text-value-compact {
        margin-top: 0px;
    }
}