.content-controller-dots {
    display: flex;
    column-gap: 0.8vw;
    justify-content: center;
    user-select: none;
}

.content-controller-dot {
    width: 3.5%;
    max-width: 20px;
    cursor: pointer;
}