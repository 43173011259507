.contact-us {
    margin-right: auto;
    margin-left: auto;
    color: gray;
}

@media screen and (min-width: 992px) {
    .contact-us {
        width: 65%;
        /* grid-template-columns: 25% auto; */
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .contact-us {
        width: 90%;
    }
}

@media screen and (max-width: 576px) {
    .contact-us {
        width: 100%;
        
    }
}

.contact-us-title {
    text-align: center;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 992px) {
    .contact-us-title {
        /* grid-column: 1 / span 2; */
        grid-column: 1 / span 3;
        font-size: 5.4vh;
        padding-bottom: 5vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .contact-us-title {
        grid-column: 1 / span 2;
        font-size: 3vw;
        padding-bottom: 5vh;
    }
}

@media screen and (max-width: 576px) {
    .contact-us-title {
        grid-column: 1 / span 3;
        font-size: 5.7vw;
        padding-bottom: 2.5vh;
    }
}

.contact-us-content {
}

@media screen and (min-width: 992px) {
    .contact-us-content {
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .contact-us-content {
    }
}

@media screen and (max-width: 576px) {
    .contact-us-content {
    }
}

.contact-us-content-form {
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
}

@media screen and (min-width: 992px) {
    .contact-us-content {
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .contact-us-content {
    }
}

@media screen and (max-width: 576px) {
    .contact-us-content {
    }
}

.contact-us-content-name {

}

.contact-us-content-input {
    width: 35%;
    max-width: 50vh;
    border-radius: 10px;
    border: 2px solid gray;
    transition: border-color 0.3s;
    height: 4vh;
    padding-left: 1vh;
    transition: all 0.3s;
}

.contact-us-content-input:focus {
    outline: none;
    border: 2px solid #94618F;
    /* box-shadow: 0 4px 20px 1px #94618f8a, 0 2px 5px 1px #979797; */
    transform: translate(0, -3px);
    box-shadow: -4px -4px 15px 8px rgba(0, 134, 255, .05), 4px 4px #5e4d93f0;
}

@media screen and (min-width: 992px) {
    .contact-us-content-input {
        font-size: 2.5vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .contact-us-content-input {
        font-size: 3vw;
    }
}

@media screen and (max-width: 576px) {
    .contact-us-content-input {
        font-size: 5.7vw;
    }
}

.contact-us-content-chat {
    width: 35%;
    max-width: 50vh;
    height: 4vh;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    user-select: none;
}

.contact-us-content-contribute {
    width: 35%;
    max-width: 50vh;
    height: 4vh;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    user-select: none;
}

.contact-us-checkbox {
    width: 3vh;
    height: 3vh;
    border-radius: 3px;
    box-sizing: border-box;
    transition: all 0.3s;
}
.contact-us-checkbox:hover {
    /* box-shadow: 0 1px 11px 3px #94618f8a; */
    transform: translate(0, -1px);
    box-shadow: -2px -2px 15px 8px rgba(0, 134, 255, .05), 2px 2px #5e4d93f0;
}

.contact-us-checkbox-unchecked {
    border: 2px solid #94618F;
}

.contact-us-checkbox-checked {
    background-image: linear-gradient(45deg, #94618F 15%, #5f4d93 85%);
    box-shadow: 0 1px 14px 1px #94618f8a;
}

.contact-us-label {
    font-size: 2.5vh;
    margin-left: 15px;
}

.contact-us-label-checked {
    color: #94618F;
    text-shadow: 0px 0px 10px #94618f5c;
}

.contact-us-content-text-message-disabled {
    visibility: hidden;
    height: 0;
}

.contact-us-content-text-message-textarea {
    height: 12vh;
    font-family: Helvetica;
    resize: none;
    padding-top: 1vh;
    padding-bottom: 1vh;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}
.contact-us-content-text-message-textarea-disabled {
    opacity: 0;
    height: 0;
}

.contact-us-content-submit-btn {
    width: 18vh;
    height: 5.5vh;
    border: 0px;
    border-radius: 8px;
    background-color: #94618F;
    color: white;
    font-size: 2.5vh;
    /* -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out; */
    transition: all 0.3s;
    cursor: pointer;
}

.contact-us-content-submit-btn:hover {
    /* background-color: #5e4d93f0; */
    transform: translate(0, -3px);
    box-shadow: -4px -4px 15px 8px rgba(0, 134, 255, .05), 4px 4px #5e4d93f0;
}

.copy-right-and-contact-info {
    margin-top: 10vh;
}