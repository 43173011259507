.App {
  text-align: center;
  overflow: auto;
}

@media screen and (min-width: 992px) {
  .App {
    overflow-y: auto;
  }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
  .App {
    overflow-y: auto;
  }
}

@media screen and (max-width: 576px) {
  .App {
    overflow-y: auto;
  }
}