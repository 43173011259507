.container {
    position: relative;
    height: 80vh;
    width: 100%;
    min-width: 950px;
    /* background-color: aqua; */
}

.industry-overview-paragraph-div {
    position: absolute;
    width: 95%;
    left:  50%;
    transform: translate(-50%, 40%);
    font-size: 1.5rem;
    line-height: 150%;
}

.animation-div {
    font-size: 1.9rem;
    font-weight: bold;
    cursor: default;
    width: '180px';
}

.industry-overview-paragraph-end-div {
    position: relative;
    top: 95%;
    font-size: 1.7rem;
}

.industry-overview-paragraph-end-pointhubjs-logo {
    position: relative;
    transform: translate(0, 40%);
    width: 10rem;
}