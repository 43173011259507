.homepage-header {
    width: 100%;
    background-image: linear-gradient(45deg, #94618F 15%, #5f4d93 85%);
    color: white;
}

@media screen and (min-width: 992px) {
    .homepage-header {
        height: 52vw;
        max-height: 130vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .homepage-header {
        height: 57vw;
    }
}

@media screen and (max-width: 576px) {
    .homepage-header {
        height: 55vh;
    }
}

.top-nav-bar {
    width: 85%;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    user-select: none;
}

@media screen and (min-width: 992px) {
    .pointhubjs-logo {
        width: 22vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .pointhubjs-logo {
        width: 36vh;
    }
}

@media screen and (max-width: 576px) {
    .pointhubjs-logo {
        width: 45%;
    }
}

@media screen and (max-width: 576px) {
    .reverse-order-mobile{
        display: flex;
        flex-direction: column-reverse;
    }
}

.homepage-header-intro {
    margin-right: auto;
    margin-left: auto;
}

@media screen and (min-width: 992px) {
    .homepage-header-intro {
        width: 75%;
        margin-top: 13vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .homepage-header-intro {
        width: 75%;
        margin-top: 6vh;
    }
}

@media screen and (max-width: 576px) {
    .homepage-header-intro {
        width: 75%;
        margin-top: 1vh;
    }
}

.homepage-header-intro-title {
    text-align: start;
    align-items: start;
    justify-content: start;
}

@media screen and (min-width: 1900px){
    .homepage-header-intro-title {
        font-size: 61px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1900px){
    .homepage-header-intro-title {
        font-size: 3.2vw;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .homepage-header-intro-title {
        font-size: 3.2vw;
    }
}

@media screen and (max-width: 576px) {
    .homepage-header-intro-title {
        display: flex;
        flex-direction: column;
        font-size: 6vw;
        gap: 10px;
    }
}

.span-typed-words {
    background-color: white;
    border-radius: 6px;
    
}

@media screen and (min-width: 992px) {
    .span-typed-words {
        padding-bottom: 10px;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .span-typed-words {
        padding-bottom: 5px;
    }
}

@media screen and (max-width: 576px) {
    .span-typed-words {
        padding-bottom: 3px;
    }
}


.typed-words::after {
    content: "|";
    display: inline;
    animation: blink 1.3s infinite;
    background-image: linear-gradient(45deg, #94618F 15%, #5f4d93 85%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}

@keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
}


.homepage-header-intro-subtitle {
    text-align: start;
    align-items: start;
    justify-content: start;
}

@media screen and (min-width: 992px) {
    .homepage-header-intro-subtitle {
        width: 85%;
        font-size: 3.1vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .homepage-header-intro-subtitle {
        width: 85%;
        font-size: 3.1vh;
    }
}

@media screen and (max-width: 576px) {
    .homepage-header-intro-subtitle {
        font-size: 3.5vw;
        width: 95%;
    }
}

.pointhubjs-homepage-2d-mockup {
    height: auto;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (min-width: 992px) {
    .pointhubjs-homepage-2d-mockup {
        width: 70vw;
        max-width: 170vh;
        margin-left: 25vw;
        margin-top: 3.5vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .pointhubjs-homepage-2d-mockup {
        width: 70vw;
        margin-left: 25vw;
        margin-top: 3.5vh;
    }
}

@media screen and (max-width: 576px) {
    .pointhubjs-homepage-2d-mockup {
        width: 40vh;
        margin-top: 1.5vh;
    }
}