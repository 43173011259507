.animated-overview {
    width: 65vw;
    max-width: 140vh;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (min-width: 577px) {
    .animated-overview {
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .animated-overview {
    }
}

@media screen and (max-width: 576px) {
    .animated-overview {
        width: 100%;
    }
}

.animated-overview-title {
    font-size: 5.4vh;
    padding-top: 4vh;
    padding-bottom: 5vh;
    color: white;
}

.animated-overview-content {
    /* grid-template-columns: 50% auto; */
    padding: 1px;
    border-radius: 5px 0 0 5px;
}

.animated-overview-content-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    color: white;
}

.animated-overview-content-first-row {

}

.animated-overview-content-industries-map {

}

.animated-overview-content-pointhubjs-features {
    margin-top: 20vh;
    padding-bottom: 10vh;
}

.animated-overview-content-marketplace {
    margin-top: 10vh;
    padding-bottom: 10vh;
}

.animated-overview-content-workspace {
    margin-top: 10vh;
    padding-bottom: 10vh;
}

.animated-overview-content-row-text {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers children vertically */
    justify-content: center; /* Centers children horizontally */
}

.animated-overview-content-row-text-title {
    padding-top: 4vh;
    padding-bottom: 3.5vh;
    font-size: 3.3vh;
}

.animated-overview-content-row-text-body {
    font-size: 2vh;
    line-height: 160%;
    text-align: left;
}

.animated-overview-content-row-animation {
    width: 50%;
}

.animated-overview-content-row-animation-video-mp4 {
    width: 85%;
}

.magnetized-element {
    /* Initial styles for the element */
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background-color: blue;
    transition: left  0.5s, top  0.5s;
  }