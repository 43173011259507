.homepage-content {
}

@media screen and (min-width: 577px) {
    .homepage-content {
        margin-top: 20vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .homepage-content {
        margin-top: 10vh;
    }
}

@media screen and (max-width: 576px) {
    .homepage-content {
        margin-top: 3vh;
    }
}

.homepage-content-business-values-div {
}

.homepage-content-animated-overview-div {
    width: 100%;
    background-color: #94618F;
    margin-top: 15vh;
    padding: 3vh 0px;
}

.homepage-content-depth-overview-div {
    width: 100%;
    padding: 3vh 0px;
}